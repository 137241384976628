import VueRouter from 'vue-router';

import AccountComponent from './pages/AccountComponent.vue';
import StartComponent from './pages/StartComponent.vue';
import ConfigComponent from './pages/ConfigComponent.vue';
import VerifyComponent from './pages/VerifyComponent.vue';
import LicenseComponent from './pages/LicenseComponent.vue';
import CheckListComponent from './pages/CheckListComponent.vue';
import CompletedComponent from './pages/CompletedComponent.vue';

const router = new VueRouter({
    routes: [
        {
            path: '/',
            redirect: '/start'
        },
        {
            path: '/start',
            component: StartComponent,
            meta: { title: "Let's Install", cp: 1 }
        },
        {
            path: '/license',
            component: LicenseComponent,
            meta: { title: 'License Agreement', cp: 2 }
        },
        {
            path: '/check_list',
            component: CheckListComponent,
            meta: { title: 'Pre-installation Checklist', cp: 3 }
        },
        {
            path: '/verify',
            component: VerifyComponent,
            meta: { title: 'Verify your license', cp: 4 }
        },
        {
            path: '/config',
            component: ConfigComponent,
            meta: { title: 'Installation Configuration', cp: 5 }
        },
        {
            path: '/account',
            component: AccountComponent,
            meta: { title: 'Create Account', cp: 6 }
        },
        {
            path: '/completed',
            component: CompletedComponent,
            meta: { title: 'Installation Completed', cp: 7 }
        },
        { path: '*', component: StartComponent }
    ],
    linkActiveClass: 'active',
    scrollBehavior: () => ({ y: 0 })
});

export default router;
