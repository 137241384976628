<template>
    <div class="tab-pane fade show active">
        <h6 class="text-uppercase">Installation progress</h6>
        <div class="progress">
            <div class="progress-bar bg-success" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <p class="mt-5 text-center text-success">
            <font-awesome-icon icon="smile" size="3x" />
        </p>
        <h6 class="text-center text-success">Installation has been successfully completed.</h6>
        <p class="mt-5 mb-4 font-weight-bold">Please don't forget to provide your valued feedback after using our item, we are always listening to improve our items.</p>
        <p>If this installation is for test purpose, you may want to generate the demo data.</p>
        <button type="button" class="btn btn-warning" @click="generateRandomData()" :disabled="loading || isSaved">
            <span v-if="loading">
                <font-awesome-icon icon="spinner" spin fixed-width /> Generate Random Data
            </span>
            <span v-else>
                <font-awesome-icon icon="database" fixed-width /> Generate Random Data
            </span>
        </button>
        <div class="buttons">
            <span></span>
            <button type="button" class="btn btn-outline-primary" href="#" :disabled="loading" @click="goToApp()">
                <font-awesome-icon icon="spinner" fixed-width spin v-if="loading" /> Finalize the Installation
                <font-awesome-icon icon="long-arrow-alt-right" fixed-width v-if="!loading" />
            </button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isSaved: false,
            loading: false
        };
    },
    mounted() {
        let installer = this.$storage.read('installer');
        if (installer && installer.step == '/account') {
            this.$storage.update('step', '/completed', 'installer');
        } else {
            this.$router.push('/start');
        }
    },
    beforeDestroy: function() {
        this.$storage.clearAll();
    },
    methods: {
        generateRandomData() {
            this.loading = true;
            axios
                .post('/install/demo', { done: true })
                .then(({ data }) => {
                    if (data.success) {
                        this.isSaved = true;
                    } else {
                        this.notify(data.message);
                        this.message = data.message;
                    }
                    this.loading = false;
                })
                .catch(err => {
                    console.error(err.response);
                    this.notify(err.response.data.message);
                });
        },
        goToApp() {
            this.loading = true;
            axios
                .post('/install/finalize', { done: true })
                .then(({ data }) => {
                    if (data.success) {
                        this.$storage.clearAll();
                        window.location.reload(true);
                    } else {
                        this.notify(data.message);
                        this.message = data.message;
                    }
                    this.loading = false;
                })
                .catch(err => {
                    console.error(err.response);
                    this.notify(err.response.data.message);
                });
        },
        notify(error) {
            this.$notify({
                type: 'error',
                duration: 10000,
                group: 'default',
                title: 'Error!',
                text: error
            });
            this.error = true;
            this.loading = false;
        }
    }
};
</script>
