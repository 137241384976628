<template>
    <div class="tab-pane fade show active">
        <h6 class="text-uppercase">Installation progress</h6>
        <div class="progress">
            <div
                class="progress-bar bg-success"
                role="progressbar"
                style="width: 75%"
                aria-valuenow="75"
                aria-valuemin="0"
                aria-valuemax="100"
            ></div>
        </div>
        <p class="my-2">Please fill the require fields below</p>
        <div class="alert alert-danger my-2" role="alert" v-if="error">
            <span v-html="message"></span>
        </div>
        <form class="label-right">
            <div class="form-group row">
                <label for="name" class="col-sm-3 col-form-label">Name</label>
                <div class="col-sm-9">
                    <input type="text" v-model="form.name" class="form-control" id="name" placeholder="Full Name" />
                </div>
            </div>
            <div class="form-group row">
                <label for="username" class="col-sm-3 col-form-label">Username</label>
                <div class="col-sm-9">
                    <input type="text" v-model="form.username" class="form-control" id="username" placeholder="Userame" />
                </div>
            </div>
            <div class="form-group row">
                <label for="email" class="col-sm-3 col-form-label">Email</label>
                <div class="col-sm-9">
                    <input type="text" v-model="form.email" class="form-control" id="email" placeholder="Email Address" />
                </div>
            </div>
            <div class="form-group row">
                <label for="password" class="col-sm-3 col-form-label">Password</label>
                <div class="col-sm-9">
                    <input type="password" v-model="form.password" class="form-control" id="password" placeholder="Password" />
                </div>
            </div>
            <div class="form-group row">
                <label for="password_confirmation" class="col-sm-3 col-form-label">Confirm Password</label>
                <div class="col-sm-9">
                    <input
                        type="password"
                        v-model="form.password_confirmation"
                        class="form-control"
                        id="password_confirmation"
                        placeholder="Confirm Password"
                    />
                </div>
            </div>
        </form>
        <div class="buttons">
            <button type="button" class="btn btn-outline-danger" @click="$router.push('/start')" :disabled="loading">
                Cancel
            </button>
            <button type="button" class="btn btn-outline-primary" @click="checkDBConfig" :disabled="loading">
                <font-awesome-icon icon="spinner" fixed-width spin v-if="loading" /> Next
                <font-awesome-icon icon="long-arrow-alt-right" fixed-width v-if="!loading" />
            </button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            error: false,
            loading: false,
            form: { name: '', username: '', email: '', password: '', password_confirmation: '' },
            message: 'Unable to create account! please double check the form data before trying again.'
        };
    },
    mounted() {
        let installer = this.$storage.read('installer');
        if (installer && installer.step == '/config') {
            this.$storage.update('step', '/account', 'installer');
            if (installer.account) {
                this.form.name = installer.account.name;
                this.form.email = installer.account.email;
                this.form.username = installer.account.username;
            }
        } else {
            this.$router.push('/start');
        }
    },
    watch: {
        form: {
            handler: function(form) {
                this.$storage.update('account', { name: form.name, username: form.username, email: form.email }, 'installer');
            },
            deep: true
        }
    },
    methods: {
        checkDBConfig() {
            this.loading = true;
            if (
                this.form.name &&
                this.form.username &&
                this.form.email &&
                this.form.password &&
                this.form.password_confirmation &&
                this.form.password == this.form.password_confirmation
            ) {
                this.loading = true;
                axios
                    .post('/install/account', this.form)
                    .then(({ data }) => {
                        if (data.success) {
                            this.$router.push('/completed');
                        } else {
                            this.notify(data.message);
                            this.message = data.message;
                        }
                        this.loading = false;
                    })
                    .catch(err => {
                        console.error(err.response);
                        this.message = err.response.data.message;
                        if (err.response.data.errors) {
                            for (let [key, value] of Object.entries(err.response.data.errors)) {
                                key = key.charAt(0).toUpperCase() + key.slice(1);
                                this.message += `<br><strong>${key}</strong>: ${value}`;
                            }
                        }
                        this.notify(this.message);
                    });
            } else {
                if (this.form.password.length < 8) {
                    this.message = 'The password must be at least 8 characters.';
                } else if (this.form.password && this.form.password_confirmation && this.form.password != this.form.password_confirmation) {
                    this.message = 'Password and Confirm fields does not match. Please type both again!';
                } else {
                    this.message = 'Please fill the form (Name, Username, Email, Password and Confirm Password fields are required)';
                }
                this.notify(this.message);
            }
        },
        notify(error) {
            this.$notify({
                type: 'error',
                duration: 10000,
                group: 'default',
                title: 'Error!',
                text: error
            });
            this.error = true;
            this.loading = false;
        }
    }
};
</script>
