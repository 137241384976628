<template>
    <div class="tab-pane fade show active">
        <h6 class="text-uppercase">Installation progress</h6>
        <div class="progress">
            <div
                class="progress-bar bg-success"
                role="progressbar"
                style="width: 25%"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
            ></div>
        </div>
        <p class="my-4">Please fill the require fields below</p>
        <div class="alert alert-danger my-4" role="alert" v-if="error">
            {{ message }}
        </div>
        <form class="label-right">
            <div class="form-group row">
                <div class="col-sm-4">
                    <div class="row">
                        <label for="dbhost" class="col-sm-12 col-form-label text-left">Host</label>
                        <div class="col-sm-12">
                            <input type="text" v-model="form.dbhost" class="form-control" id="dbhost" placeholder="Host" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="row">
                        <label for="dbport" class="col-sm-12 col-form-label text-left">Port</label>
                        <div class="col-sm-12">
                            <input type="text" v-model="form.dbport" class="form-control" id="dbport" placeholder="Port" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-5">
                    <div class="row">
                        <label for="dbname" class="col-sm-12 col-form-label text-left">Database Name</label>
                        <div class="col-sm-12">
                            <input type="text" v-model="form.dbname" class="form-control" id="dbname" placeholder="Database Name" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-6">
                    <div class="row">
                        <label for="dbuser" class="col-sm-6 col-form-label text-left">Username</label>
                        <div class="col-sm-12">
                            <input type="text" v-model="form.dbuser" class="form-control" id="dbuser" placeholder="Username" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="row">
                        <label for="dbpass" class="col-sm-6 col-form-label text-left">Password</label>
                        <div class="col-sm-12">
                            <input
                                type="password"
                                v-model="form.dbpass"
                                class="form-control"
                                id="dbpass"
                                placeholder="Password (optional)"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="dbsocket" class="col-sm-12 col-form-label text-left">Unix Socket</label>
                <div class="col-sm-12">
                    <input type="text" v-model="form.dbsocket" class="form-control" id="dbsocket" placeholder="Socket (optional)" />
                </div>
            </div>
        </form>
        <div class="buttons">
            <button type="button" class="btn btn-outline-danger" @click="$router.push('/start')" :disabled="loading">
                Cancel
            </button>
            <button type="button" class="btn btn-outline-primary" @click="checkDBConfig" :disabled="loading">
                <font-awesome-icon icon="spinner" fixed-width spin v-if="loading" /> Next
                <font-awesome-icon icon="long-arrow-alt-right" fixed-width v-if="!loading" />
            </button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            error: false,
            loading: false,
            form: { dbhost: '127.0.0.1', dbport: 3306, dbname: '', dbuser: '', dbpass: '', dbsocket: '', license: {}, installation_id: '' },
            message:
                'Please double check the form data, make sure that database is empty, host is corrtect and the user have all privileges on database before trying again.'
        };
    },
    mounted() {
        let installer = this.$storage.read('installer');
        if (installer && installer.step == '/verify') {
            this.$storage.update('step', '/config', 'installer');
            this.form.license = installer.verify;
            this.form.installation_id = installer.installation_id;
            if (installer.config) {
                this.form.dbhost = installer.config.dbhost;
                this.form.dbname = installer.config.dbname;
                this.form.dbuser = installer.config.dbuser;
                this.form.dbpass = installer.config.dbpass;
                this.form.dbsocket = installer.config.dbsocket;
                this.form.dbport = installer.config.dbport ? installer.config.dbport : 3306;
            }
        } else {
            this.$router.push('/start');
        }
    },
    watch: {
        form: {
            handler: function(form) {
                this.$storage.update('config', form, 'installer');
            },
            deep: true
        }
    },
    methods: {
        checkDBConfig() {
            this.loading = true;
            if (this.form.dbhost && this.form.dbport && this.form.dbname && this.form.dbuser) {
                this.loading = true;
                axios
                    .post('/install/save', this.form)
                    .then(({ data }) => {
                        if (data && data.success) {
                            this.$router.push('/account');
                        } else {
                            this.notify(data.message);
                            this.message = data.message;
                        }
                        this.loading = false;
                    })
                    .catch(err => {
                        console.error(err.response);
                        this.notify(err.response.data.message);
                        this.message = err.response.data.message;
                    });
            } else {
                this.message = 'Please fill the form (Hostname, Port, Database name & Username fields are required)';
                this.notify(this.message);
            }
        },
        notify(error) {
            this.$notify({
                type: 'error',
                duration: 10000,
                group: 'default',
                title: 'Error!',
                text: error
            });
            this.error = true;
            this.loading = false;
        }
    }
};
</script>

<style>
.col-form-label {
    padding-top: 0;
    padding-bottom: 3px;
}
</style>
