class Storage {
    constructor() {
        this.Global = typeof window !== "undefined" ? window : global;
    }

    localStorage() {
        return this.Global.localStorage;
    }

    read(key) {
        return JSON.parse(this.localStorage().getItem(key));
    }

    write(key, value) {
        return this.localStorage().setItem(key, JSON.stringify(value));
    }

    update(key, value, main, pro) {
        main = main || "Installer";
        let data = this.read(main) || {};
        if (pro) {
            if (!data[pro]) {
                data[pro] = {};
            }
            data[pro][key] = value;
        } else {
            data[key] = value;
        }
        return this.write(main, data);
    }

    remove(key) {
        return this.localStorage().removeItem(key);
    }

    each(fn) {
        for (var i = this.localStorage().length - 1; i >= 0; i--) {
            var key = this.localStorage().key(i);
            fn(this.read(key), key);
        }
    }

    clearAll() {
        return this.localStorage().clear();
    }
}

export default Storage;
