<template>
    <div class="tab-pane fade show active">
        <h6 class="text-uppercase">Installation progress</h6>
        <div class="progress">
            <div
                class="progress-bar bg-warning"
                role="progressbar"
                style="width: 2%"
                aria-valuenow="2"
                aria-valuemin="0"
                aria-valuemax="100"
            ></div>
        </div>
        <p class="mt-3 mb-3">
            The License grants you, the purchaser, an ongoing and non-exclusive rights to make use of the item you have purchased.
        </p>
        <table class="table table-striped table-sm table-borderless text-center">
            <thead class="thead-dark">
                <tr>
                    <td width="35%"></td>
                    <td>
                        <strong>Regular</strong><br />
                        <!-- <small>
                            (
                            <a href="#">view full license</a>
                            )
                        </small> -->
                    </td>
                    <td>
                        <strong>Developer</strong><br />
                        <!-- <small>
                            (
                            <a href="#">view full license</a>
                            )
                        </small> -->
                    </td>
                    <td v-if="extended">
                        <strong>Extended</strong><br />
                        <small>
                            (
                            <a href="#">view full license</a>
                            )
                        </small>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-right font-weight-bold">End product</td>
                    <td>1</td>
                    <td>1</td>
                    <td v-if="extended">1</td>
                </tr>
                <tr>
                    <td class="text-right font-weight-bold">Active Installs</td>
                    <td>1</td>
                    <td>1</td>
                    <td v-if="extended">Unlimited</td>
                </tr>
                <tr>
                    <td class="text-right font-weight-bold">Modifiable</td>
                    <td class="position-relative">
                        <font-awesome-icon icon="times" fixed-width class="text-danger" />
                        <!-- <small class="position-absolute text-success" style="margin:-1px 0 0 0;display:inline-block;">*</small> -->
                    </td>
                    <td>
                        <font-awesome-icon icon="check" fixed-width class="text-success" />
                    </td>
                    <td v-if="extended">
                        <font-awesome-icon icon="check" fixed-width class="text-success" />
                    </td>
                </tr>
                <tr>
                    <td class="text-right font-weight-bold">Re-sell/Re-distribute</td>
                    <td>
                        <font-awesome-icon icon="times" fixed-width class="text-danger" />
                    </td>
                    <td>
                        <font-awesome-icon icon="times" fixed-width class="text-danger" />
                    </td>
                    <td v-if="extended">
                        <font-awesome-icon icon="check" fixed-width class="text-success" />
                    </td>
                </tr>
                <tr>
                    <td class="text-right font-weight-bold">Use in other items</td>
                    <td>
                        <font-awesome-icon icon="times" fixed-width class="text-danger" />
                    </td>
                    <td>
                        <font-awesome-icon icon="times" fixed-width class="text-danger" />
                    </td>
                    <td v-if="extended">
                        <font-awesome-icon icon="check" fixed-width class="text-success" />
                    </td>
                </tr>
            </tbody>
        </table>
        <p class="mb-0 text-info">
            <font-awesome-icon icon="info-circle" class="mr-2" />
            <strong>Note to freelancers and creative agencies:</strong>
        </p>
        <p class="pl-4 mb-3">
            You may charge your client for your services to create an end product, under the Developer License. But you can’t use single
            license on multiple clients or jobs.
        </p>
        <!-- <p class="text-uppercase font-weight-bold mb-1">
            <span class="text-success"> * </span>
            <small>Standalone Applications/software can't be modified under Regular License.</small>
        </p> -->
        <p class="font-weight-bold">
            I accept & understand, license violation could result in termination.
        </p>
        <div class="buttons">
            <button type="button" class="btn btn-outline-danger" @click="$router.push('/start')">
                Cancel
            </button>
            <button type="button" class="btn btn-outline-primary" @click="$router.push('/check_list')">
                Accept
                <font-awesome-icon icon="long-arrow-alt-right" fixed-width />
            </button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            extended: false
        };
    },
    mounted() {
        let installer = this.$storage.read('installer');
        if (installer && installer.step == '/start') {
            this.$storage.update('step', '/license', 'installer');
        } else {
            this.$router.push('/start');
        }
    }
};
</script>
