<template>
  <div>
    <div class="box d-flex align-items-stretch">
      <div class="contents">
        <nav-component></nav-component>
      </div>
      <div class="details">
        <div class="container-fluid">
          <div class="tab-content px-4 py-5">
            <transition mode="out-in" name="fade" appear>
              <router-view></router-view>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <vue-progress-bar></vue-progress-bar>
    <notifications group="default" animation-name="fade" />
  </div>
</template>

<script>
import NavComponent from './components/NavComponent';
export default {
    components: { NavComponent }
};
</script>

<style lang="scss">
@import 'sass/app.scss';
</style>
