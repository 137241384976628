import Vue from "vue";
import App from "./App.vue";
window.axios = require("axios");
import Storage from "./Storage";
import router from "./router.js";
import VueRouter from "vue-router";
import VueProgressBar from "vue-progressbar";
import Notifications from "vue-notification";
import { library } from "@fortawesome/fontawesome";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
    faSync,
    faCheck,
    faTimes,
    faSmile,
    faSpinner,
    faDatabase,
    faInfoCircle,
    faCheckDouble,
    faTimesCircle,
    faCheckCircle,
    faQuestionCircle,
    faLongArrowAltLeft,
    faLongArrowAltRight
} from "@fortawesome/free-solid-svg-icons";

library.add(faQuestionCircle, faCheck, faCheckCircle, faLongArrowAltLeft, faLongArrowAltRight, faCheckDouble, faTimes, faTimesCircle, faInfoCircle, faSync, faSmile, faSpinner, faDatabase);
Vue.component(FontAwesomeIcon.name, FontAwesomeIcon);

window.Vue = Vue;
Vue.config.performance = true;
Vue.config.productionTip = false;
const vpOpts = { color: "#fff", failedColor: "#dc3545", thickness: "2px" };

Vue.use(VueRouter);
Vue.use(Notifications);
Vue.use(VueProgressBar, vpOpts);
Vue.prototype.$storage = new Storage();
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.headers.common["X-CSRF-TOKEN"] = document.querySelector('meta[name="csrf-token"]').getAttribute("content");

const app = new Vue({
    router,
    mounted() {
        this.$Progress.finish();
    },
    created() {
        this.$Progress.start();
        this.$router.beforeEach((to, from, next) => {
            document.title = to.meta.title;
            this.$Progress.start();
            next();
        });
        this.$router.afterEach(() => {
            this.$Progress.finish();
        });
        window.axios.interceptors.request.use(
            config => {
                this.$Progress.start();
                return config;
            },
            error => {
                this.$Progress.fail();
                return Promise.reject(error);
            }
        );
        window.axios.interceptors.response.use(
            response => {
                this.$Progress.finish();
                return response;
            },
            error => {
                this.$Progress.fail();
                return Promise.reject(error);
            }
        );
    },
    render: h => h(App)
}).$mount("#app");
